<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('withdraw_requests')" @filter-div-status="datatable.filterStatus = $event"
                    :isFilter="true" />
            </template>
            <template v-slot:header-mobile>
                <Header-mobile @filter-div-status="datatable.filterStatus = $event" :title="$t('withdraw_requests')"
                    :isFilter="true" />
            </template>
            <div>
                <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                    :export-excel="true" @exportExcel="downloadExcel">
                    <b-row>
                        <b-col md="6">
                            <b-form-group :label="$t('semester')">
                                <SemestersSelectbox v-model="datatable.queryParams.filter.semester_id"></SemestersSelectbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('student_number')">
                                <b-form-input v-model="datatable.queryParams.filter.student_number"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('name')">
                                <b-form-input v-model="datatable.queryParams.filter.name"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('program_name')">
                                <program-selectbox v-model="datatable.queryParams.filter.program_code"></program-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('surname')">
                                <b-form-input v-model="datatable.queryParams.filter.surname"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </datatable-filter>

                <datatable ref="datatable" :isLoading.sync="datatable.isLoading" :columns="datatable.columns"
                    :rows="datatable.rows" :total="datatable.total" :queryParams="datatable.queryParams"
                    @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                    :lineNumbers="false" v-show="datatable.showTable">
                </datatable>

            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import Datatable from "@/components/datatable/Datatable";

import WithDrawService from "@/services/WithDrawService";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import qs from "qs";

export default {
    components: {
        SemestersSelectbox,
        ProgramSelectbox,
        FacultySelectbox,
        DatatableFilter,
        Datatable,
        HeaderMobile,
        Header,
        AppLayout
    },
    metaInfo() {
        return {
            title: this.$t('withdraw_requests')
        }
    },
    data() {
        return {
            trainee: {},
            student: {},
            datatable: {
                columns: [
                    {
                        label: "",
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('detail'),
                                class: 'ri-search-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.detail(row)
                                }
                            }
                        ]
                    },
                    { field: "student_number", label: this.toUpperCase("student_number"), hidden: false, sortable: false },
                    { field: "name", label: this.toUpperCase("name"), hidden: false, sortable: false },
                    { field: "surname", label: this.toUpperCase("surname"), hidden: false, sortable: false },
                    { field: "faculty_name", label: this.toUpperCase("faculty_name"), hidden: false, sortable: false },
                    { field: "program_name", label: this.toUpperCase("program_name"), hidden: false, sortable: false },
                    { field: "academic_year", label: this.toUpperCase("academic_year"), hidden: false, sortable: false },
                    { field: "semester", label: this.toUpperCase("semester"), hidden: false, sortable: false },
                    { field: "slot_type", label: this.toUpperCase("slot_type"), hidden: false, sortable: false },
                    { field: "slot_semester", label: this.toUpperCase("slot_semester"), hidden: false, sortable: false },
                    { field: "course_code", label: this.toUpperCase("course_code"), hidden: false, sortable: false },
                    { field: "course_name", label: this.toUpperCase("course_name"), hidden: false, sortable: false },
                    { field: "credit", label: this.toUpperCase("credit"), hidden: false, sortable: false },
                    { field: "ects_credit", label: this.toUpperCase("ects_credit"), hidden: false, sortable: false },
                    { field: "created_at", label: this.toUpperCase("created_at"), hidden: false, sortable: false },
                ],
                showTable: false,
                isLoading: false,
                filterStatus: true,
                total: 0,
                rows: [],
                queryParams: {
                    filter: {},
                    page: 1,
                    limit: 20
                }
            },
        }
    },
    created() {
        this.filterSet();
    },

    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {
                student_number: null,
                name: null,
                surname: null,
                semester_id: null,
                faculty_code: null,
                program_code: null
            }
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.filterSet();
            this.datatable.queryParams.page = 1; // Sayfa numarasını her zaman 1 olarak ayarlayın
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        excelExport() {
            
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return WithDrawService.getAllWithDraw(config)
                .then(response => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                }).catch(e => {
                    this.showErrors(e)
                }).finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        downloadExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams,
                    limit: "-1"
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            WithDrawService.getAllWithDraw(config)
                .then(response => {
                    const headers = [
                        { header: this.$t('student_number'), key: "student_number", width: 20 },
                        { header: this.$t('name'), key: "name", width: 20 },
                        { header: this.$t('surname'), key: "surname", width: 20 },
                        { header: this.$t('faculty_name'), key: "faculty_name", width: 20 },
                        { header: this.$t('program_name'), key: "program_name", width: 20 },
                        { header: this.$t('academic_year'), key: "academic_year", width: 20 },
                        { header: this.$t('semester'), key: "semester", width: 20 },
                        { header: this.$t('slot_type'), key: "slot_type", width: 20 },
                        { header: this.$t('slot_semester'), key: "slot_semester", width: 20 },
                        { header: this.$t('course_code'), key: "course_code", width: 20 },
                        { header: this.$t('course_name'), key: "course_name", width: 20 },
                        { header: this.$t('credit'), key: "credit", width: 20 },
                        { header: this.$t('ects_credit'), key: "ects_credit", width: 20 },
                        { header: this.$t('created_at'), key: "created_at", width: 20 },
                    ]
                    const excelData = response.data.data.map(i => ({
                        student_number: i.student_number,
                        name: i.name,
                        surname: i.surname,
                        faculty_name: i.faculty_name,
                        program_name: i.program_name,
                        academic_year: i.academic_year,
                        semester: i.semester,
                        slot_type: i.slot_type,
                        slot_semester: i.slot_semester,
                        course_code: i.course_code,
                        course_name: i.course_name,
                        credit: i.credit,
                        ects_credit: i.ects_credit,
                        created_at: i.created_at,
                    }))
                    this._downloadExcelFile({ data: excelData, filename: `${this.$t('withdraw_requests').toLowerCase()}-${this.$t('excel').toLowerCase()}.xlsx`, headers })
                }).catch(e => {
                    this.showErrors(e)
                }).finally(() => {

                });
        },
        detail(row) {
            this.$router.push({ path: "/request/withDraw/detail/" + row.id })
        }
    }
}
</script>
